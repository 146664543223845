<template>
    <div class="not-found">
        <img class="thum" src="../assets/images/icon-not.png" />
        <!-- <p class="tips"><span>暂无符合该条件的结果</span></p> -->
    </div>
</template>

<script>
    export default {
        name: 'notFound',
        data() {
return{}
        }
    }
</script>

<style lang="scss">
    .not-found{
        text-align:center;
        .thum{
            margin:10px 0 20px;
            max-width:100%;
        }
        .tips{
            font-size:18px;
            color:#676767;
        }
    }
</style>
