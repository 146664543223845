<template>
    <div class="localExperience center">
        <bread-crumb></bread-crumb>
        <div class="notice-content">
            <policy-list :listData="policyData"></policy-list>
            <not-found v-if="policyData.length === 0"></not-found>
        </div>
        <div class="pagination">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="start"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
    import policyList from '../../components/policyList'
    import breadCrumb from '@/components/common/breadCrumb'
    import {getGraphInfoByClass} from "../../api/homeService";
    import notFound from "../../components/notFound";

    export default {
        components: {
            policyList,
            breadCrumb,
            notFound,
        },
        data() {
            return {
                start:1,
                size:10,
                total:0,
                policyData: [],
                isActive:true,
            }
        },
        mounted(){
            this.getGraphInfoList();
        },
        methods:{
            clickTab(tab, event) {
                this.getGraphInfoList();
            },
            getGraphInfoList(){
                const data = {lx:'4',start:this.start,size:this.size};
                getGraphInfoByClass(data).then(res=>{
                    this.policyData=res.data;
                    this.total = res.total;
                })
            },
            handleSizeChange(val){
                this.size = val;
                this.getGraphInfoList();
            },
            handleCurrentChange(val){
                this.start = val;
                this.getGraphInfoList();
            },

        }
    }
</script>
<style lang="scss">
    .localExperience {
        .active {
            color: rgba(0, 120, 255, 1);
        }
        .zcfg-tabs {
            border: none;
            box-shadow: none;
            .block{
                padding:15px 0px;
            }
            .el-tabs__header {
                margin: 20px 0 0px;
                border-bottom:1px solid #ECF1F3;
                background-color: transparent;

                .el-tabs__item {
                    border: none;
                    font-size: 16px;
                    padding: 0px 0px !important;
                    color: #000000;
                    line-height: 40px;
                    width: 192px;
                    height: 40px;
                    border-radius: 6px 6px 0px 0px;
                    text-align: center;
                }

                .el-tabs__item.is-active {
                    background-color: #ECF1F3;
                }

                .el-tabs__item:not(.is-active):hover {
                    color: #808080;
                }

                .el-tabs__active-bar {
                    display: none;
                }
            }

            .el-tabs__content {
                padding: 0 !important;
            }

            .el-tabs__nav-wrap::after {
                display: none;
            }
        }
    }
    .pagination{
        text-align:center;
        margin:20px 0;
        .el-pagination{
            font-weight:500;
        }
    }
</style>
