<template>
  <div class="policy-list">
    <div class="policy-list-item" v-for="(list, index) of listData" :key="index">
      <el-row :gutter="20">
        <el-col :span="3" class="date-col">
          <span class="year">{{ year(list.fbsj) }}年</span>
          <span class="date">{{ date(list.fbsj) }}</span>
        </el-col>
        <el-col :span="18">
          <p class="con-title" @click="toDetail(list)">{{ list.bt }}</p>
          <p v-if="showText" class="con-text">{{ list.wznr }}</p>
        </el-col>
        <el-col :span="3">
          <button @click="toDetail(list)">查看详情</button>
        </el-col>
      </el-row>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    listData: {
      type: Array,
      default: () => ({})
    },
    showText:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {}
  },
  methods: {
    toDetail(item) {
      let name  = '';
      if(item.lx === '5' || item.lx === '6' || item.lx === '7'){
        name = 'policyDetail'
      }else if(item.lx === '2'){
        name = 'noticeDetail'
      }else if(item.lx === '4'){
        name = 'localExperienceDetail'
      }else{
        name = 'policyReadDetail'
      }
      this.$router.push({
        name: name,
        query: {
          id: item.id
        }
      });
    },
    year(value) {
      return value.substring(0, 4)
    },
    date(value) {
      return value.substring(5).replace('.', '/')
    }
  }
}
</script>

<style lang="scss">
.policy-list {
  .el-row {
    height: 136px;
    border-bottom: 1px solid rgba(217, 217, 217, 1);

    .el-col-3 {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-top: 25px;

      .year {
        font-size: 16px;
        font-family: 'PangMenZhengDao';
        font-weight: 400;
        padding: 18px 0px 5px 0px;
        color: rgba(255, 148, 83, 1);
      }

      .date {
        font-size: 16px;
        font-family: REEJI-HonghuangLi-MediumGB1 .0;
        font-weight: 400;
        color: rgba(255, 148, 83, 1);
      }

      button {
        width: 100px;
        height: 30px;
        cursor: pointer;
        background: rgba(255, 255, 255, 1);
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        border: 1px solid rgba(217, 217, 217, 1);
        margin: 0 0px 0px 24px;
      }
    }

    .el-col-18 {
      .con-title {
        max-height: 30px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 30px;
        margin: 22px 0px 15px 0px;
        cursor: pointer;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .con-text {
        max-height: 36px;
        font-size: 14px;
        line-height: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .date-col {
      min-height: 80px;
      background: url(../assets/images/icon_date_bg.png) 20px center no-repeat;
    }
  }

  .page-block {
    padding: 31px 240px 91px 240px;
  }

  .el-row:hover {
    background: rgba(242, 242, 242, 1);

    .el-col-18 {
      .con-title {
        color: rgba(0, 120, 255, 1);
      }
    }

    .el-col-3 {
      button {
        background: rgba(3, 155, 229, 1);
        color: white;
      }
    }
  }
}
</style>
